import React, { useState } from "react";

const Password = ({ children }) => {
  const [password, setPassword] = useState("");

  const isAuthenticated = localStorage.getItem("isAuthenticated");

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (password === "thundarian-bb") {
      localStorage.setItem("isAuthenticated", "true");
      window.location.reload();
    }
  };

  return (
    <>
      {isAuthenticated ? (
        children
      ) : (
        <div className="password-page">
          <div className="content">
            <h2 style={{ color: "white" }}>Blackman Builders Coming Soon!</h2>
            <p>Enter the password to access the site.</p>
            <form onSubmit={handlePasswordSubmit}>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
              />
              <button type="submit">Enter</button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default Password;
