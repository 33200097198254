import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Password from "./pages/Password";

const Home = lazy(() => import("./pages/Home"));
const About = lazy(() => import("./pages/About"));
const Projects = lazy(() => import("./pages/project/Projects"));
// const ProjectDetails = lazy(() => import("./pages/project/ProjectDetails"));
const Contact = lazy(() => import("./pages/Contact"));
const FourOhFour = lazy(() => import("./pages/404"));

const App = () => {
  return (
    <Router>
      <Suspense fallback={<div />}>
        <Routes>
          <Route
            path="/"
            element={
              <Password>
                <Home />
              </Password>
            }
          />

          <Route
            path="/about"
            element={
              <Password>
                <About />
              </Password>
            }
          />
          <Route
            path="/projects"
            element={
              <Password>
                <Projects />
              </Password>
            }
          />
          {/* <Route path="/project-details" element={<ProjectDetails />} /> */}
          <Route
            path="/contact-us"
            element={
              <Password>
                <Contact />
              </Password>
            }
          />
          <Route
            path="*"
            element={
              <Password>
                <FourOhFour />
              </Password>
            }
          />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
